import React, { useEffect } from "react";
import { redirectTo } from "src/helpers/route";
const Redirect = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      redirectTo("/links");
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return <p>Redirecionando...</p>;
};
export default Redirect;
/*
import React from "react";
import YoutubeVideo from "src/components/YoutubeVideo";
import { FacebookProvider, Comments } from "react-facebook";

import SEO from "src/components/SEO";
import About from "src/sections/About";
import Footer from "src/sections/Footer";
import {
  BASE_URL,
  NAME,
  FACEBOOK_APP_ID,
  MARATONA_JS_3,
} from "src/helpers/config";

import Dates from "src/components/Dates";
import maratonaDates from "../dates.json";

import "../maratona.scss";

const Dia1 = () => {
  const videoId = "-w-rRpk1Clg";

  const seoProps = {
    title: `Maratona JS 3 - Dia 01 - ${NAME}`,
    canonical: `${BASE_URL}/mararona-js/dia-1/`,
    description: `A carreira de Dev e suas oportunidades! - ${MARATONA_JS_3}.`,
    image: `https://i3.ytimg.com/vi/${videoId}/maxresdefault.jpg`,
  };
  return (
    <div className="maratona-js-3 video-page">
      <SEO {...seoProps} />
      <div className="wrapper">
        <div className="header">
          <div className="logo">
            <img
              loading="lazy"
              src="/images/maratona-js-3-logo-white.png"
              alt={`Logo ${MARATONA_JS_3}`}
            />
          </div>
        </div>
        <Dates dates={maratonaDates} />

        <h1>Dia 1</h1>
        <YoutubeVideo id={videoId} />
        <div className="description">
          <p>
            Seja bem vindo ao primeiro dia da {MARATONA_JS_3}! <br />
            Espero que você aproveite bastante! <br />
            <strong>Lista de links:</strong>
          </p>
          <div className="links">
            <a
              href="https://emersonbroga.com/sobre"
              target="_blank"
              rel="noopener noreferrer"
            >
              Sobre Emerson Broga
            </a>
            <a
              href="https://www.youtube.com/watch?v=kEk82-bVf_Q"
              target="_blank"
              rel="noopener noreferrer"
            >
              Como instalar o MongoDB
            </a>
            <a
              href="https://gist.github.com/emersonbroga/cd13134691297e6bd4ce32ecee4a4255"
              target="_blank"
              rel="noopener noreferrer"
            >
              Arquivo Games.json
            </a>
            <a
              href="https://www.youtube.com/watch?v=vP_Bj7nfrJ4"
              target="_blank"
              rel="noopener noreferrer"
            >
              Tutorial GitHub em 20 Minutos
            </a>

            <a
              href="https://www.youtube.com/watch?v=D-QIbgPPRBQ"
              target="_blank"
              rel="noopener noreferrer"
            >
              Tutorial Git em 20 Minutos
            </a>
          </div>
        </div>
      </div>
      <div className="comments">
        <div className="wrapper">
          <h3>Comentários:</h3>
          <FacebookProvider appId={FACEBOOK_APP_ID}>
            <Comments href={seoProps.canonical} width="100%" />
          </FacebookProvider>
        </div>
      </div>

      <About />
      <Footer />
    </div>
  );
};

export default Dia1;
*/
